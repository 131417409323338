import { useEffect, useState } from "react";

const Input = (props) => {
  const [Attributes, setAttributes] = useState({
    name: "",
    label: "",
    value: "",
    disabled: false,
    helpText: "",
    onChange: null,
    options: [],
    multiselect: false,
  });

  useEffect(() => {
    setAttributes({
      name: props.name,
      label: props.label,
      value: props.value,
      disabled: props.disabled ? true : false,
      helpText: props.helpText ? props.helpText : "",
      onChange: props.onChange,
      options: props.options,
      multiselect: props.multiselect ? props.multiselect : false,
      error: props.error ? "is-invalid" : "",
    });
  }, [props]);

  return (
    <div className="my-4">
      <label htmlFor={Attributes.name} className="form-label">
        {Attributes.label}
      </label>
      <select
        onChange={(e) => {
          Attributes.onChange(e);
        }}
        id={Attributes.name}
        name={Attributes.name}
        className={`form-select form-select-lg ${Attributes.error}`}
        aria-describedby={`${Attributes.name}HelpBox`}
        disabled={Attributes.disabled}
        multiple={props.multiselect}
      >
        <option value="">&nbsp;</option>
        {Attributes.options.map((value, index) => {
          return (
            <option value={value} key={index} selected={Attributes.value === value ? true : false}>
              {value}
            </option>
          );
        })}
      </select>
      <div id={`${Attributes.name}HelpBox`} className="form-text">
        {Attributes.helpText}
      </div>
      {JSON.stringify(Attributes.onChange)}
    </div>
  );
};

export default Input;
