import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { cccuApr2022 as Airtable } from "../../airtable";
import Input from "../form/input";
import Select from "../form/select";
import Textarea from "../form/textarea";
import File from "../form/file";

import Country from "../../assets/countries.json";

import CCCUStudentHandbook from "../../assets/downloads/cccu_student_info_booklet_2021.pdf";

const Register = () => {
  const { id } = useParams();
  const { push } = useHistory();

  const [University] = useState("Canterbury Christ Church University");
  const [CampusData, setCampusData] = useState([]);

  useEffect(() => {
    Airtable("campus")
      .select({
        view: "Grid view",
      })
      .firstPage((err, records) => {
        if (err) console.error(err);
        const campus = [];
        records.forEach((record) => {
          campus.push({ id: record.id, name: record.get("name") });
        });
        setCampusData(campus);
      });
  }, []);

  const [countries] = useState([
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ]);

  const [current_uk_residence_status_options] = useState([
    "UK Citizen",
    "EU Scheme Settled",
    "EU Scheme Pre-Settled",
    "EU Family Member",
    "EU Pending application for EU Scheme",
    "Non-EU Indefinite Leave to Remain",
    "Non-EU Settlement",
    "Non-EU Leave to Remain",
    "Non-EU Refugee",
    "Other",
  ]);
  const [photo_id_options] = useState([
    "Passport",
    "National ID",
    "Driving License",
    "Biometric Residence Permit (BRP)",
    "Other",
  ]);
  const [employment_status_options] = useState([
    "Not working",
    "Working Full-time",
    "Working Part-time Minimum 25h per week",
    "Working Part-time Less than 25h per week",
    "Working as Self-employed",
    "Retired",
  ]);
  const [programme_options] = useState([
    "BA Hons Business Studies with Foundation Year",
    "Business Studies Year 3 Top-up",
    "Public Health and Health Promotion Top up",
  ]);
  const [classes_mode_options] = useState([
    "DAY Sessions",
    "EVENING Sessions - from 18:00 PM",
  ]);
  const [applying_for_the_first_time_options] = useState(["Yes", "No"]);
  const [did_you_complete_the_course] = useState(["Yes", "No"]);
  const [received_funds_from_student_finance_options] = useState([
    "No",
    "Yes - for less than an year",
    "Yes - for one full academic year",
    "Yes - for more than an year",
    "Yes - for two or more academic years",
    "Yes - other",
  ]);
  const [how_many_terms_funded_by_student_finance_options] = useState([
    "1 Term",
    "2 Terms",
    "3 Terms",
  ]);
  const [academic_level_from_student_finance_options] = useState([
    "Level 2",
    "Level 3 (e.g. NVQ, Foundation level)",
    "Level 4 (e.g. BA, BSC, HND)",
    "Level 5",
    "Other level",
  ]);
  const [status_of_student_finance_application_options] = useState([
    "Fully Approved",
    "Approved - Changes Required (CoC)",
    "Approved - £0 tuition fees",
    "Processing - All documents provided",
    "Processing - Pending documents",
    "Application not submitted",
    "Rejected by SFE",
  ]);
  const [supported_documents_for_loan_application_options] = useState([
    "3 months invoices",
    "3 months residency evidence",
    "3 months payslip",
    "3 years residency evidence",
    "5 years residence evidence",
    "Child benefit letter or tax credit or universal credit (if applicable)",
    "Child birth certificate (if applicable)",
    "Documents proving the relationship to a UK/EU Family Member",
    "Employment contract",
    "EU21 Form 2021/2022",
    "Marriage certificate or absolute decree (if applicable)",
    "Original ID or Passport",
    "PN1 Form 2021/2022",
    "UK Residence Card",
    "UK/EU Family Member's 3 month's payslips",
    "UK/EU Family Member's employment contract",
    "UK/EU Family Member's passport or ID",
    "UTR letter",
    "EU Settlement Scheme Proof (Share Code)",
  ]);
  const [Student, setStudent] = useState({
    id: "",
    campus: "",
    forename: "",
    surname: "",
    date_of_birth: "",
    email_address: "",
    phone_number: "",
    living_in_uk: "",
    current_uk_residence_status: "",
    current_uk_residence_status_document: "",
    current_uk_residence_status_document_page_2: "",
    uk_immigration_share_code: "",
    photo_id: "",
    photo_id_document: "",
    photo_id_document_page_2: "",
    employment_status: "",
    programme: "",
    classes_mode: "",
    applying_for_the_first_time: "",
    received_funds_from_student_finance: "",
    academic_level_from_student_finance: "",
    status_of_student_finance_application: "",
    crn: "",
    ssn: "",
    changes_to_sfe_application: "",
    comments_for_student_finance_application: "",
    supported_documents_for_loan_application: "",
    date_posted_student_finance_documents: "",
    evidence_student_finance_application: "",
    full_name: "",
    date_of_declaration: "",
    picture: "",
    did_you_complete_the_course_comments: "",
    did_you_complete_the_course: "",
    how_many_terms_funded_by_student_finance: "",
    before_uk: "",
    education_details: "",
    education_details_1: "",
    education_details_2: "",
    education_1: "",
    education_2: "",
    education_3: "",
    education_country_1: "",
    education_country_2: "",
    education_country_3: "",
  });
  const [Error, setError] = useState({
    id: false,
    campus: false,
    forename: false,
    surname: false,
    date_of_birth: false,
    email_address: false,
    phone_number: false,
    living_in_uk: false,
    current_uk_residence_status: false,
    current_uk_residence_status_document: false,
    current_uk_residence_status_document_page_2: false,
    uk_immigration_share_code: false,
    photo_id: false,
    photo_id_document: false,
    photo_id_document_page_2: false,
    employment_status: false,
    programme: false,
    classes_mode: false,
    applying_for_the_first_time: false,
    received_funds_from_student_finance: false,
    academic_level_from_student_finance: false,
    status_of_student_finance_application: false,
    crn: false,
    ssn: false,
    changes_to_sfe_application: false,
    comments_for_student_finance_application: false,
    supported_documents_for_loan_application: false,
    date_posted_student_finance_documents: false,
    full_name: false,
    picture: false,
    evidence_student_finance_application: false,
    did_you_complete_the_course_comments: false,
    did_you_complete_the_course: false,
    how_many_terms_funded_by_student_finance: false,
    before_uk: false,
    education_details: false,
    education_details_1: false,
    education_details_2: false,
    education_1: false,
    education_2: false,
    education_3: false,
    education_country_1: false,
    education_country_2: false,
    education_country_3: false,
  });
  useEffect(() => {
    Airtable("students").find(id, function (err, record) {
      if (err) console.error(err);
      if (record) {
        setStudent({
          id: "id" in record.fields ? record.fields.id : "",
          campus: "campus" in record.fields ? record.fields.campus[0] : "",
          forename: "forename" in record.fields ? record.fields.forename : "",
          surname: "surname" in record.fields ? record.fields.surname : "",
          date_of_birth:
            "date_of_birth" in record.fields ? record.fields.date_of_birth : "",
          email_address:
            "email_address" in record.fields ? record.fields.email_address : "",
          phone_number:
            "phone_number" in record.fields ? record.fields.phone_number : "",
          living_in_uk:
            "living_in_uk" in record.fields ? record.fields.living_in_uk : "",
          current_uk_residence_status:
            "current_uk_residence_status" in record.fields
              ? record.fields.current_uk_residence_status
              : "",
          current_uk_residence_status_document:
            "current_uk_residence_status_document" in record.fields
              ? record.fields.current_uk_residence_status_document
              : "",
          current_uk_residence_status_document_page_2:
            "current_uk_residence_status_document_page_2" in record.fields
              ? record.fields.current_uk_residence_status_document_page_2
              : "",
          uk_immigration_share_code:
            "uk_immigration_share_code" in record.fields
              ? record.fields.uk_immigration_share_code
              : "",
          photo_id: "photo_id" in record.fields ? record.fields.photo_id : "",
          photo_id_document:
            "photo_id_document" in record.fields
              ? record.fields.photo_id_document
              : "",
          photo_id_document_page_2:
            "photo_id_document_page_2" in record.fields
              ? record.fields.photo_id_document_page_2
              : "",
          employment_status:
            "employment_status" in record.fields
              ? record.fields.employment_status
              : "",
          programme:
            "programme" in record.fields ? record.fields.programme : "",
          classes_mode:
            "classes_mode" in record.fields ? record.fields.classes_mode : "",
          applying_for_the_first_time:
            "applying_for_the_first_time" in record.fields
              ? record.fields.applying_for_the_first_time
              : "",
          received_funds_from_student_finance:
            "received_funds_from_student_finance" in record.fields
              ? record.fields.received_funds_from_student_finance
              : "",
          academic_level_from_student_finance:
            "academic_level_from_student_finance" in record.fields
              ? record.fields.academic_level_from_student_finance
              : "",
          status_of_student_finance_application:
            "status_of_student_finance_application" in record.fields
              ? record.fields.status_of_student_finance_application
              : "",
          crn: "crn" in record.fields ? record.fields.crn : "",
          ssn: "ssn" in record.fields ? record.fields.ssn : "",
          changes_to_sfe_application:
            "changes_to_sfe_application" in record.fields
              ? record.fields.changes_to_sfe_application
              : "",
          comments_for_student_finance_application:
            "comments_for_student_finance_application" in record.fields
              ? record.fields.comments_for_student_finance_application
              : "",
          supported_documents_for_loan_application:
            "supported_documents_for_loan_application" in record.fields
              ? record.fields.supported_documents_for_loan_application
              : "",
          date_posted_student_finance_documents:
            "date_posted_student_finance_documents" in record.fields
              ? record.fields.date_posted_student_finance_documents
              : "",
          full_name:
            "full_name" in record.fields ? record.fields.full_name : "",
          picture: "picture" in record.fields ? record.fields.picture : "",
          evidence_student_finance_application:
            "evidence_student_finance_application" in record.fields
              ? record.fields.evidence_student_finance_application
              : "",
          did_you_complete_the_course_comments:
            "did_you_complete_the_course_comments" in record.fields
              ? record.fields.did_you_complete_the_course_comments
              : "",
          did_you_complete_the_course:
            "did_you_complete_the_course" in record.fields
              ? record.fields.did_you_complete_the_course
              : "",
          how_many_terms_funded_by_student_finance:
            "how_many_terms_funded_by_student_finance" in record.fields
              ? record.fields.how_many_terms_funded_by_student_finance
              : "",
          before_uk:
            "before_uk" in record.fields ? record.fields.before_uk : "",
          education_details:
            "education_details" in record.fields
              ? record.fields.education_details
              : "",
          education_details_1:
            "education_details_1" in record.fields
              ? record.fields.education_details_1
              : "",
          education_details_2:
            "education_details_2" in record.fields
              ? record.fields.education_details_2
              : "",
          education_1:
            "education_1" in record.fields ? record.fields.education_1 : "",
          education_2:
            "education_2" in record.fields ? record.fields.education_2 : "",
          education_3:
            "education_3" in record.fields ? record.fields.education_3 : "",
          education_country_1:
            "education_country_1" in record.fields
              ? record.fields.education_country_1
              : "",
          education_country_2:
            "education_country_2" in record.fields
              ? record.fields.education_country_2
              : "",
          education_country_3:
            "education_country_3" in record.fields
              ? record.fields.education_country_3
              : "",
        });
      } else {
        push("/cccu-apr-2022/");
      }
    });
  }, [id, push]);

  const HandleChange = (e) => {
    setStudent({ ...Student, [e.target.name]: e.target.value });
  };

  const HandleState = (name, value) => {
    setStudent({ ...Student, [name]: value });
  };

  const HandleValidate = (name, form) => {
    let flag = 0;
    const error = {
      id: false,
      campus: false,
      forename: false,
      surname: false,
      date_of_birth: false,
      email_address: false,
      phone_number: false,
      living_in_uk: false,
      current_uk_residence_status: false,
      current_uk_residence_status_document: false,
      uk_immigration_share_code: false,
      photo_id: false,
      photo_id_document: false,
      photo_id_document_page_2: false,
      employment_status: false,
      programme: false,
      classes_mode: false,
      applying_for_the_first_time: false,
      received_funds_from_student_finance: false,
      academic_level_from_student_finance: false,
      status_of_student_finance_application: false,
      crn: false,
      ssn: false,
      changes_to_sfe_application: false,
      comments_for_student_finance_application: false,
      supported_documents_for_loan_application: false,
      date_posted_student_finance_documents: false,
      evidence_student_finance_application: false,
      full_name: false,
      picture: false,
      did_you_complete_the_course_comments: false,
      did_you_complete_the_course: false,
      how_many_terms_funded_by_student_finance: false,
      before_uk: false,
      education_details: false,
      education_details_1: false,
      education_details_2: false,
      education_1: false,
      education_2: false,
      education_3: false,
      education_country_1: false,
      education_country_2: false,
      education_country_3: false,
    };

    if (name === "id" || form) {
      if (Student.id === "") {
        error.id = true;
        flag++;
      } else {
        error.id = false;
      }
    }

    if (name === "forename" || form) {
      if (Student.forename === "") {
        error.forename = true;
        flag++;
      } else {
        error.forename = false;
      }
    }

    if (name === "surname" || form) {
      if (Student.surname === "") {
        error.surname = true;
        flag++;
      } else {
        error.surname = false;
      }
    }

    if (name === "date_of_birth" || form) {
      if (Student.date_of_birth === "") {
        error.date_of_birth = true;
        flag++;
      } else {
        error.date_of_birth = false;
      }
    }

    if (name === "email_address" || form) {
      if (Student.email_address === "") {
        error.email_address = true;
        flag++;
      } else {
        error.email_address = false;
      }
    }

    if (name === "phone_number" || form) {
      if (Student.phone_number === "") {
        error.phone_number = true;
        flag++;
      } else {
        error.phone_number = false;
      }
    }

    if (name === "living_in_uk" || form) {
      if (Student.living_in_uk === "") {
        error.living_in_uk = true;
        flag++;
      } else {
        error.living_in_uk = false;
      }
    }

    if (name === "current_uk_residence_status" || form) {
      if (Student.current_uk_residence_status === "") {
        error.current_uk_residence_status = true;
        flag++;
      } else {
        error.current_uk_residence_status = false;
      }
    }

    if (name === "photo_id" || form) {
      if (Student.photo_id === "") {
        error.photo_id = true;
        flag++;
      } else {
        error.photo_id = false;
      }
    }

    if (name === "employment_status" || form) {
      if (Student.employment_status === "") {
        error.employment_status = true;
        flag++;
      } else {
        error.employment_status = false;
      }
    }

    if (name === "programme" || form) {
      if (Student.programme === "") {
        error.programme = true;
        flag++;
      } else {
        error.programme = false;
      }
    }

    if (name === "classes_mode" || form) {
      if (Student.classes_mode === "") {
        error.classes_mode = true;
        flag++;
      } else {
        error.classes_mode = false;
      }
    }

    if (name === "applying_for_the_first_time" || form) {
      if (Student.applying_for_the_first_time === "") {
        error.applying_for_the_first_time = true;
        flag++;
      } else {
        error.applying_for_the_first_time = false;
      }
    }

    if (name === "status_of_student_finance_application" || form) {
      if (Student.status_of_student_finance_application === "") {
        error.status_of_student_finance_application = true;
        flag++;
      } else {
        error.status_of_student_finance_application = false;
      }
    }

    if (name === "full_name" || form) {
      if (Student.full_name === "") {
        error.full_name = true;
        flag++;
      } else {
        error.full_name = false;
      }
    }

    setError(error);
    return flag;
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const error = HandleValidate("", true);
    if (error === 0) {
      Airtable("students").update(
        [
          {
            id: id,
            fields: {
              forename: Student.forename,
              surname: Student.surname,
              date_of_birth: Student.date_of_birth,
              email_address: Student.email_address,
              phone_number: Student.phone_number,
              living_in_uk: Student.living_in_uk,
              current_uk_residence_status: Student.current_uk_residence_status,
              current_uk_residence_status_document:
                Student.current_uk_residence_status_document,
              current_uk_residence_status_document_page_2:
                Student.current_uk_residence_status_document_page_2,
              uk_immigration_share_code: Student.uk_immigration_share_code,
              photo_id: Student.photo_id,
              photo_id_document: Student.photo_id_document,
              photo_id_document_page_2: Student.photo_id_document_page_2,
              employment_status: Student.employment_status,
              programme: Student.programme,
              classes_mode: Student.classes_mode,
              applying_for_the_first_time: Student.applying_for_the_first_time,
              received_funds_from_student_finance:
                Student.received_funds_from_student_finance,
              academic_level_from_student_finance:
                Student.academic_level_from_student_finance,
              status_of_student_finance_application:
                Student.status_of_student_finance_application,
              crn: Student.crn,
              ssn: Student.ssn,
              changes_to_sfe_application: Student.changes_to_sfe_application,
              comments_for_student_finance_application:
                Student.comments_for_student_finance_application,
              supported_documents_for_loan_application:
                Student.supported_documents_for_loan_application,
              date_posted_student_finance_documents:
                Student.date_posted_student_finance_documents === ""
                  ? null
                  : Student.date_posted_student_finance_documents,
              full_name: Student.full_name,
              picture: Student.picture,
              evidence_student_finance_application:
                Student.evidence_student_finance_application,
              date_of_declaration: new Date(),
              did_you_complete_the_course_comments:
                Student.did_you_complete_the_course_comments,
              did_you_complete_the_course: Student.did_you_complete_the_course,
              how_many_terms_funded_by_student_finance:
                Student.how_many_terms_funded_by_student_finance,
              before_uk: Student.before_uk,
              education_details: Student.education_details,
              education_details_1: Student.education_details_1,
              education_details_2: Student.education_details_2,
              education_1: Student.education_1,
              education_2: Student.education_2,
              education_3: Student.education_3,
              education_country_1: Student.education_country_1,
              education_country_2: Student.education_country_2,
              education_country_3: Student.education_country_3,
            },
          },
        ],
        function (err, records) {
          if (err) {
            console.log(err);
          } else {
            if (records) {
              push("/cccu-apr-2022/thankyou");
            }
          }
        }
      );
    }
  };

  return (
    <section className="container-fluid p-3">
      <div className="text-center">
        <h5>LSC Admission Process</h5>
        <h4 style={{ color: "red" }}>
          This checklist needs to be filled by the applicant only{" "}
        </h4>
        <p>
          Please complete and submit the below questionnaire prior to your
          one-to-one virtual registration with an LSC Student Advisor.
        </p>
      </div>
      <div className="container bg-light border px-3">
        <form onSubmit={HandleSubmit}>
          <Input
            label="Student Reference ID"
            name="id"
            helpText={`ID sent on email`}
            disabled={true}
            value={Student.id}
            onChange={HandleChange}
            error={Error.id}
          />
          <div className="my-4">
            <label htmlFor="campus" className="form-label">
              Campus
            </label>
            <select
              onChange={HandleChange}
              id="campus"
              name="campus"
              className={`form-select form-select-lg ${Error.campus}`}
              aria-describedby="campusHelpBox"
            >
              {CampusData.map((value, index) => {
                return (
                  <option
                    value={value.id}
                    key={index}
                    selected={Student.campus === value.id ? true : false}
                  >
                    {value.name}
                  </option>
                );
              })}
            </select>
            <div id={`campusHelpBox`} className="form-text">
              Please select the campus of study?
            </div>
          </div>

          <Input
            label="First name"
            name="forename"
            helpText="As shown on your Passport"
            value={Student.forename}
            error={Error.forename}
            onChange={HandleChange}
          />
          <Input
            label="Last name"
            name="surname"
            helpText="As shown on your Passport"
            value={Student.surname}
            error={Error.surname}
            onChange={HandleChange}
          />
          <Input
            label="Date of birth (yyyy/mm/dd)"
            name="date_of_birth"
            helpText="Please confirm your date of birth - YYYY/MM/DD"
            type="date"
            value={Student.date_of_birth}
            error={Error.date_of_birth}
            onChange={HandleChange}
          />
          <Input
            label="Email address"
            name="email_address"
            helpText="Please confirm your email address"
            value={Student.email_address}
            error={Error.email_address}
            onChange={HandleChange}
          />
          <Input
            label="Phone number"
            name="phone_number"
            helpText="Please confirm your UK Mobile number"
            value={Student.phone_number}
            error={Error.phone_number}
            type="tel"
            onChange={HandleChange}
          />
          <Input
            label="When have you started living in the UK? (yyyy/mm/dd)"
            name="living_in_uk"
            type="date"
            value={Student.living_in_uk}
            error={Error.living_in_uk}
            onChange={HandleChange}
          />
          <Input
            label="Where have you been living before moving to the UK?"
            name="before_uk"
            type="text"
            value={Student.before_uk}
            error={Error.before_uk}
            onChange={HandleChange}
          />
          <Select
            label="What is your current UK Residence Status?"
            name="current_uk_residence_status"
            helpText="Please select your immigration status in the UK"
            value={Student.current_uk_residence_status}
            error={Error.current_uk_residence_status}
            onChange={HandleChange}
            options={current_uk_residence_status_options}
          />
          {Student.current_uk_residence_status !== "UK Citizen" &&
          Student.current_uk_residence_status !==
            "Non-EU Indefinite Leave to Remain" &&
          Student.current_uk_residence_status !== "Non-EU Settlement" &&
          Student.current_uk_residence_status !== "Non-EU Refugee" &&
          Student.current_uk_residence_status !== "Non-EU Leave to Remain" ? (
            <>
              <Input
                label="UK Immigration share code"
                name="uk_immigration_share_code"
                value={Student.uk_immigration_share_code}
                error={Error.uk_immigration_share_code}
                onChange={HandleChange}
              />
              <small>
                Prove your immigration status.{" "}
                <a
                  target="_blank"
                  href="https://www.gov.uk/view-prove-immigration-status"
                  rel="noreferrer"
                >
                  Click here to know more
                </a>
              </small>
            </>
          ) : (
            <></>
          )}
          <File
            label="Home office residence document"
            name="current_uk_residence_status_document"
            value={Student.current_uk_residence_status_document}
            error={Error.current_uk_residence_status_document}
            helpText="Please upload the image of the BRP or the EU settlement letter"
            onChange={HandleChange}
            onStateChange={HandleState}
          />
          <File
            label="Home office residence document Page 2"
            name="current_uk_residence_status_document_page_2"
            value={Student.current_uk_residence_status_document_page_2}
            error={Error.current_uk_residence_status_document_page_2}
            helpText="Please upload the image of the BRP or the EU settlement letter"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <Select
            label="Photo ID for verification"
            name="photo_id"
            helpText="Which Photo ID and/or Immigration Document you will be presented to prove your Identification and Residence Status?"
            value={Student.photo_id}
            error={Error.photo_id}
            onChange={HandleChange}
            options={photo_id_options}
          />
          <File
            label="Upload Photo ID for verification"
            name="photo_id_document"
            value={Student.photo_id_document}
            error={Error.photo_id_document}
            helpText="Please upload the picture of your photo ID"
            onChange={HandleChange}
            onStateChange={HandleState}
          />
          <File
            label="Upload Photo ID for verification Page 2"
            name="photo_id_document_page_2"
            value={Student.photo_id_document_page_2}
            error={Error.photo_id_document_page_2}
            helpText="Please upload the picture of your photo ID"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <Select
            label="Employment status"
            name="employment_status"
            helpText="Are you currently working?"
            value={Student.employment_status}
            error={Error.employment_status}
            onChange={HandleChange}
            options={employment_status_options}
          />
          <Select
            label="Programme of study"
            name="programme"
            helpText="What programme are you applying for?"
            value={Student.programme}
            error={Error.programme}
            onChange={HandleChange}
            options={programme_options}
          />
          <Select
            label="Class Mode"
            name="classes_mode"
            helpText="Are you enrolling for Day or Evening classes?"
            value={Student.classes_mode}
            error={Error.classes_mode}
            onChange={HandleChange}
            options={classes_mode_options}
          />
          <h6 style={{ color: "red" }}>
            Note: Please provide accurate information below. By submitting the
            Fees Assessment checklist, you declare that the information you have
            provided is true and correct. You also understand that any wilful
            dishonesty might cause challenges to your enrolment completion and
            Student Finance application approval.
          </h6>
          <Select
            label="Is this your first time applying for the Student Finance Loan for tuition fees and/or maintenance?"
            name="applying_for_the_first_time"
            value={Student.applying_for_the_first_time}
            error={Error.applying_for_the_first_time}
            onChange={HandleChange}
            options={applying_for_the_first_time_options}
          />
          {Student.applying_for_the_first_time === "No" ? (
            <>
              <Select
                label="Have you ever received funds from the Student Finance Company for previous studies and course(s)?"
                name="received_funds_from_student_finance"
                value={Student.received_funds_from_student_finance}
                error={Error.received_funds_from_student_finance}
                onChange={HandleChange}
                options={received_funds_from_student_finance_options}
              />

              {Student.received_funds_from_student_finance ===
              "Yes - for more than an year" ? (
                <Select
                  label="How many terms were funded by the Student Finance (SLC)?"
                  name="how_many_terms_funded_by_student_finance"
                  value={Student.how_many_terms_funded_by_student_finance}
                  error={Error.how_many_terms_funded_by_student_finance}
                  onChange={HandleChange}
                  options={how_many_terms_funded_by_student_finance_options}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {Student.received_funds_from_student_finance !== "No" &&
          Student.applying_for_the_first_time === "No" ? (
            <>
              <Select
                label="For which academic level have you already received previous funds from the Student Finance Company?"
                name="academic_level_from_student_finance"
                value={Student.academic_level_from_student_finance}
                error={Error.academic_level_from_student_finance}
                onChange={HandleChange}
                options={academic_level_from_student_finance_options}
              />
              <Select
                label="Did you complete the course?"
                name="did_you_complete_the_course"
                value={Student.did_you_complete_the_course}
                error={Error.did_you_complete_the_course}
                onChange={HandleChange}
                options={did_you_complete_the_course}
              />
              {Student.did_you_complete_the_course === "No" ? (
                <Textarea
                  label="Why did you leave the course?"
                  name="did_you_complete_the_course_comments"
                  value={Student.did_you_complete_the_course_comments}
                  error={Error.did_you_complete_the_course_comments}
                  onChange={HandleChange}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <Select
            label={`What is the current status of your student finance application for the academic year 2021/2022?`}
            name="status_of_student_finance_application"
            value={Student.status_of_student_finance_application}
            error={Error.status_of_student_finance_application}
            onChange={HandleChange}
            options={status_of_student_finance_application_options}
          />

          {Student.status_of_student_finance_application !==
          "Application not submitted" ? (
            <Input
              label="What is your Student Finance - Customer Reference Number (CRN)?"
              name="crn"
              value={Student.crn}
              error={Error.crn}
              onChange={HandleChange}
            />
          ) : (
            <></>
          )}

          {[
            "Fully Approved",
            "Approved - Changes Required (CoC)",
            "Approved - £0 tuition fees",
          ].includes(Student.status_of_student_finance_application) ? (
            <Input
              label="Please provide your Student Support Number (SSN)"
              name="ssn"
              helpText="You can find the SSN number below the bar code on the 'Payment Advice' page of your entitlement approval letter"
              value={Student.ssn}
              error={Error.ssn}
              onChange={HandleChange}
            />
          ) : (
            <></>
          )}

          {Student.status_of_student_finance_application ===
          "Approved - Changes Required (CoC)" ? (
            <Textarea
              label="What changes are required to your SFE's application?"
              name="changes_to_sfe_application"
              value={Student.changes_to_sfe_application}
              error={Error.changes_to_sfe_application}
              onChange={HandleChange}
            />
          ) : (
            <></>
          )}

          {["Approved - £0 tuition fees", "Rejected by SFE"].includes(
            Student.status_of_student_finance_application
          ) ? (
            <Textarea
              label="Please add your own comments regarding your Student Finance application for this course."
              name="comments_for_student_finance_application"
              value={Student.comments_for_student_finance_application}
              error={Error.comments_for_student_finance_application}
              onChange={HandleChange}
            />
          ) : (
            <></>
          )}

          {[
            "Processing - All documents provided",
            "Processing - Pending documents",
            "Rejected by SFE",
          ].includes(Student.status_of_student_finance_application) ? (
            <>
              <Select
                label="Which supporting documents have you already provided to the Student Finance Company to process your loan application?"
                name="supported_documents_for_loan_application"
                value={Student.supported_documents_for_loan_application}
                error={Error.supported_documents_for_loan_application}
                onChange={HandleChange}
                options={supported_documents_for_loan_application_options}
                multiselect={true}
              />
              <Input
                label="When have you posted your most recent document to the Student Finance Company?"
                name="date_posted_student_finance_documents"
                value={Student.date_posted_student_finance_documents}
                error={Error.date_posted_student_finance_documents}
                type="date"
                onChange={HandleChange}
              />
            </>
          ) : (
            <></>
          )}

          <File
            label="Upload Evidence of Student Finance Application or Approval Letter"
            name="evidence_student_finance_application"
            value={Student.evidence_student_finance_application}
            error={Error.evidence_student_finance_application}
            helpText="Please Upload Evidence of Student Finance Application or Approval Letter"
            onChange={HandleChange}
            onStateChange={HandleState}
          />
          <br />
          <br />
          <h4>
            Please provide the details of your previous qualification (School or
            college from overseas or the UK)
          </h4>
          <hr />

          <Textarea
            label="Education 1: Name and address of the Previous School/College - Qualification achieved - Course start date (MM/YYYY) - end date (MM/YYYY)"
            name="education_details"
            value={Student.education_details}
            error={Error.education_details}
            onChange={HandleChange}
          />

          <Select
            label="Country of Education 1"
            name="education_country_1"
            helpText="Please select the country of education"
            value={Student.education_country_1}
            error={Error.education_country_1}
            onChange={HandleChange}
            options={countries}
          />

          <File
            label="Education 1: Upload you educational certificates or transcripts"
            name="education_1"
            value={Student.education_1}
            error={Error.education_1}
            helpText="Please Upload the evidence of Education"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <Textarea
            label="Education 2: Name and address of the Previous School/College - Qualification achieved - Course start date (MM/YYYY) - end date (MM/YYYY)"
            name="education_details_1"
            value={Student.education_details_1}
            error={Error.education_details_1}
            onChange={HandleChange}
          />
          <Select
            label="Country of Education 2"
            name="education_country_2"
            helpText="Please select the country of education"
            value={Student.education_country_2}
            error={Error.education_country_2}
            onChange={HandleChange}
            options={countries}
          />

          <File
            label="Education 2: Upload you educational certificates or transcripts"
            name="education_2"
            value={Student.education_2}
            error={Error.education_2}
            helpText="Please Upload the evidence of Education"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <Textarea
            label="Education 3: Name and address of the Previous School/College - Qualification achieved - Course start date (MM/YYYY) - end date (MM/YYYY)"
            name="education_details_2"
            value={Student.education_details_2}
            error={Error.education_details_2}
            onChange={HandleChange}
          />

          <Select
            label="Country of Education 3"
            name="education_country_3"
            helpText="Please select the country of education"
            value={Student.education_country_3}
            error={Error.education_country_3}
            onChange={HandleChange}
            options={countries}
          />

          <File
            label="Education 3: Upload you educational certificates or transcripts"
            name="education_3"
            value={Student.education_3}
            error={Error.education_3}
            helpText="Please Upload the evidence of Education"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <>
            <h3>Liability Policy - Academic Year 2021/22</h3>
            <hr />
            <ul>
              <li>
                {University} (LSC Campus) is prepared to allow you to enrol to
                your course of studies on the understanding that you will
                provide.
                <ul>
                  <li>
                    An acknowledgement of your completed student finance
                    application from the Student Loan Company (SLC) for the
                    current academic year; – either in paper or electronic form
                    AND
                  </li>
                  <li>
                    Evidence that your application has been approved by the
                    Student Loan Company (SLC) by the start of your programme
                    with the April-2022 intake ({Student.programme}).
                  </li>
                </ul>
              </li>
              <li>
                If your application is delayed you may not receive any
                maintenance grant or loans for subsistence that you may be
                eligible for, or any bursaries or scholarships from {University}{" "}
                that you may be entitled to.
              </li>
              <li>
                By signing this declaration, you are also acknowledging that if
                you do not receive funding from the SLC, you will be personally
                liable for the payment of your tuition fees. In the event this
                is not received, the college's Tuition Fees and Refund policies
                in respect of non-payment will apply.
              </li>
              <li>
                Learners who interrupt their studies without seeking and gaining
                the agreement of the college shall remain liable for full
                payments of the tuition fees.
              </li>
            </ul>
            <br />
            <table className="table table-dark table-striped">
              <thead>
                <tr>
                  <th>Liability Point based on date of withdrawal</th>
                  <th>Liability as % of full year tuition fees</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Liability Start Date from 02-May-2022</td>
                  <td>25% of fee due</td>
                </tr>
                <tr>
                  <td>From beginning of Term 2 to the end of Term 2</td>
                  <td>50% of fee due</td>
                </tr>
                <tr>
                  <td>From beginning of Term 3 and beyond </td>
                  <td>100% of fee due</td>
                </tr>
              </tbody>
            </table>
            <br />
            <div className="d-grid gap-4">
              <a
                href="https://www.canterbury.ac.uk/our-students/ug-current/support-services/personal-support/studying-at-a-partner-institution"
                target="_blank"
                rel="noreferrer"
                className="btn btn-warning"
              >
                Click here to access the Student Information portal
              </a>
            </div>
          </>

          <Input
            label="Signature"
            name="full_name"
            value={Student.full_name}
            error={Error.full_name}
            helpText="Please enter your full name as a signature"
            onChange={HandleChange}
          />
          <Input
            label="Date of declaration"
            name="date"
            value={`${new Date().getDate()}-${
              new Date().getMonth() + 1
            }-${new Date().getFullYear()}`}
            error={""}
            helpText=""
            disabled="true"
            onChange={HandleChange}
          />
          <File
            label="Please take a selfie photo as a signature"
            name="picture"
            value={Student.picture}
            error={Error.picture}
            helpText="As part of the online registration you are required an ID check"
            onChange={HandleChange}
            onStateChange={HandleState}
          />
          <h2>Applicant Declaration</h2>
          <hr />
          <p>
            I confirm that the information given on my application and this form
            is true, complete and accurate and no information or other material
            information has been omitted. I accept that if this is not the case,
            UCAS shall have the right to cancel my application and admission,
            and I shall have no claim against UCAS or any higher education
            institution or college in relation thereto.
            <br />
            <br />
            I confirm that I understand my data will be shared with UCAS and
            processed in accordance with their Privacy policy including use for
            statistical purposes.
            <br />
            <br />
            I also accept that having completed this form you do not have the
            right to further choices within the UCAS scheme and will not
            approach any other university or college in the UCAS scheme.
            <br />
            <br />I have the right to cancel this application, if I decide not
            to take up my place at the university or college, I must do this by
            informing the university or college who have offered me the place as
            soon as possible.
            <br />
            <br />
            <strong>
              By submitting the form, you are agreeing to the University TUITION
              Fee POLICY and the financial LIABILITY terms.
            </strong>
          </p>
          <br />
          <br />
          <div className="d-grid gap-4">
            <button type="submit" className="btn btn-primary btn-lg">
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Register;
