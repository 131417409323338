import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { uosJuly2022 as Airtable } from "../../airtable";
import Input from "../form/input";
import Select from "../form/select";
import Textarea from "../form/textarea";
import File from "../form/file";

const Register = () => {
  const { id } = useParams();
  const { push } = useHistory();

  const [current_uk_residence_status_options] = useState([
    "UK Citizen",
    "EU Scheme Settled",
    "EU Scheme Pre-Settled",
    "EU Family Member",
    "EU Pending application for EU Scheme",
    "Non-EU Indefinite Leave to Remain",
    "Non-EU Settlement",
    "Non-EU Leave to Remain",
    "Non-EU Refugee",
    "Other",
  ]);
  const [applying_for_the_first_time_options] = useState(["Yes", "No"]);
  const [did_you_complete_the_course] = useState(["Yes", "No"]);
  const [received_funds_from_student_finance_options] = useState([
    "No",
    "Yes - for less than an year",
    "Yes - for one full academic year",
    "Yes - for more than an year",
    "Yes - for two or more academic years",
    "Yes - other",
  ]);
  const [how_many_terms_funded_by_student_finance_options] = useState([
    "1 Term",
    "2 Terms",
    "3 Terms",
  ]);
  const [academic_level_from_student_finance_options] = useState([
    "Level 2",
    "Level 3 (e.g. NVQ, Foundation level)",
    "Level 4 (e.g. BA, BSC, HND)",
    "Level 5",
    "Other level",
  ]);
  const [status_of_student_finance_application_options] = useState([
    "Fully Approved",
    "Approved - Changes Required (CoC)",
    "Approved - £0 tuition fees",
    "Processing - All documents provided",
    "Processing - Pending documents",
    "Application not submitted",
    "Rejected by SFE",
  ]);
  const [supported_documents_for_loan_application_options] = useState([
    "3 months invoices",
    "3 months residency evidence",
    "3 months payslip",
    "3 years residency evidence",
    "5 years residence evidence",
    "Child benefit letter or tax credit or universal credit (if applicable)",
    "Child birth certificate (if applicable)",
    "Documents proving the relationship to a UK/EU Family Member",
    "Employment contract",
    "EU21 Form 2021/2022",
    "Marriage certificate or absolute decree (if applicable)",
    "Original ID or Passport",
    "PN1 Form 2021/2022",
    "UK Residence Card",
    "UK/EU Family Member's 3 month's payslips",
    "UK/EU Family Member's employment contract",
    "UK/EU Family Member's passport or ID",
    "UTR letter",
    "EU Settlement Scheme Proof (Share Code)",
  ]);

  const [Student, setStudent] = useState({
    id: "",
    forename: "",
    surname: "",
    date_of_birth: "",
    living_in_uk: "",
    before_uk: "",
    current_uk_residence_status: "",
    current_uk_residence_status_document: "",
    current_uk_residence_status_document_page_2: "",
    uk_immigration_share_code: "",
    applying_for_the_first_time: "",
    received_funds_from_student_finance: "",
    academic_level_from_student_finance: "",
    status_of_student_finance_application: "",
    crn: "",
    ssn: "",
    changes_to_sfe_application: "",
    comments_for_student_finance_application: "",
    supported_documents_for_loan_application: "",
    date_posted_student_finance_documents: "",
    evidence_student_finance_application: "",
    did_you_complete_the_course_comments: "",
    did_you_complete_the_course: "",
    how_many_terms_funded_by_student_finance: "",
  });
  const [Error, setError] = useState({
    id: false,
    current_uk_residence_status: false,
    current_uk_residence_status_document: false,
    current_uk_residence_status_document_page_2: false,
    uk_immigration_share_code: false,
    applying_for_the_first_time: false,
    received_funds_from_student_finance: false,
    academic_level_from_student_finance: false,
    status_of_student_finance_application: false,
    crn: false,
    ssn: false,
    changes_to_sfe_application: false,
    comments_for_student_finance_application: false,
    supported_documents_for_loan_application: false,
    date_posted_student_finance_documents: false,
    did_you_complete_the_course_comments: false,
    did_you_complete_the_course: false,
    how_many_terms_funded_by_student_finance: false,
    date_of_birth: false,
    living_in_uk: false,
    before_uk: false,
  });
  useEffect(() => {
    Airtable("students").find(id, function (err, record) {
      if (err) console.error(err);
      if (record) {
        setStudent({
          id: "id" in record.fields ? record.fields.id : "",
          forename: "forename" in record.fields ? record.fields.forename : "",
          surname: "surname" in record.fields ? record.fields.surname : "",
          current_uk_residence_status:
            "current_uk_residence_status" in record.fields
              ? record.fields.current_uk_residence_status
              : "",
          current_uk_residence_status_document:
            "current_uk_residence_status_document" in record.fields
              ? record.fields.current_uk_residence_status_document
              : "",
          current_uk_residence_status_document_page_2:
            "current_uk_residence_status_document_page_2" in record.fields
              ? record.fields.current_uk_residence_status_document_page_2
              : "",
          uk_immigration_share_code:
            "uk_immigration_share_code" in record.fields
              ? record.fields.uk_immigration_share_code
              : "",
          applying_for_the_first_time:
            "applying_for_the_first_time" in record.fields
              ? record.fields.applying_for_the_first_time
              : "",
          received_funds_from_student_finance:
            "received_funds_from_student_finance" in record.fields
              ? record.fields.received_funds_from_student_finance
              : "",
          academic_level_from_student_finance:
            "academic_level_from_student_finance" in record.fields
              ? record.fields.academic_level_from_student_finance
              : "",
          status_of_student_finance_application:
            "status_of_student_finance_application" in record.fields
              ? record.fields.status_of_student_finance_application
              : "",
          crn: "crn" in record.fields ? record.fields.crn : "",
          ssn: "ssn" in record.fields ? record.fields.ssn : "",
          changes_to_sfe_application:
            "changes_to_sfe_application" in record.fields
              ? record.fields.changes_to_sfe_application
              : "",
          comments_for_student_finance_application:
            "comments_for_student_finance_application" in record.fields
              ? record.fields.comments_for_student_finance_application
              : "",
          supported_documents_for_loan_application:
            "supported_documents_for_loan_application" in record.fields
              ? record.fields.supported_documents_for_loan_application
              : "",
          date_posted_student_finance_documents:
            "date_posted_student_finance_documents" in record.fields
              ? record.fields.date_posted_student_finance_documents
              : "",
          did_you_complete_the_course_comments:
            "did_you_complete_the_course_comments" in record.fields
              ? record.fields.did_you_complete_the_course_comments
              : "",
          did_you_complete_the_course:
            "did_you_complete_the_course" in record.fields
              ? record.fields.did_you_complete_the_course
              : "",
          how_many_terms_funded_by_student_finance:
            "how_many_terms_funded_by_student_finance" in record.fields
              ? record.fields.how_many_terms_funded_by_student_finance
              : "",
          date_of_birth:
            "date_of_birth" in record.fields ? record.fields.date_of_birth : "",
          living_in_uk:
            "living_in_uk" in record.fields ? record.fields.living_in_uk : "",
          before_uk:
            "before_uk" in record.fields ? record.fields.before_uk : "",
        });
      } else {
        push("/uos-jul-2022-share-code");
      }
    });
  }, [id, push]);

  const HandleChange = (e) => {
    setStudent({ ...Student, [e.target.name]: e.target.value });
  };

  const HandleState = (name, value) => {
    setStudent({ ...Student, [name]: value });
  };

  const HandleValidate = (name, form) => {
    let flag = 0;
    const error = {
      id: false,
      forename: false,
      surname: false,
      current_uk_residence_status: false,
      current_uk_residence_status_document: false,
      uk_immigration_share_code: false,
      applying_for_the_first_time: false,
      received_funds_from_student_finance: false,
      academic_level_from_student_finance: false,
      status_of_student_finance_application: false,
      crn: false,
      ssn: false,
      changes_to_sfe_application: false,
      comments_for_student_finance_application: false,
      supported_documents_for_loan_application: false,
      date_posted_student_finance_documents: false,
      evidence_student_finance_application: false,
      did_you_complete_the_course_comments: false,
      did_you_complete_the_course: false,
      how_many_terms_funded_by_student_finance: false,
      date_of_birth: false,
      living_in_uk: false,
      before_uk: false,
    };

    if (name === "id" || form) {
      if (Student.id === "") {
        error.id = true;
        flag++;
      } else {
        error.id = false;
      }
    }

    if (name === "forename" || form) {
      if (Student.forename === "") {
        error.forename = true;
        flag++;
      } else {
        error.forename = false;
      }
    }

    if (name === "surname" || form) {
      if (Student.surname === "") {
        error.surname = true;
        flag++;
      } else {
        error.surname = false;
      }
    }

    if (name === "current_uk_residence_status" || form) {
      if (Student.current_uk_residence_status === "") {
        error.current_uk_residence_status = true;
        flag++;
      } else {
        error.current_uk_residence_status = false;
      }
    }

    if (name === "applying_for_the_first_time" || form) {
      if (Student.applying_for_the_first_time === "") {
        error.applying_for_the_first_time = true;
        flag++;
      } else {
        error.applying_for_the_first_time = false;
      }
    }

    if (name === "status_of_student_finance_application" || form) {
      if (Student.status_of_student_finance_application === "") {
        error.status_of_student_finance_application = true;
        flag++;
      } else {
        error.status_of_student_finance_application = false;
      }
    }

    setError(error);
    return flag;
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const error = HandleValidate("", true);
    if (error === 0) {
      Airtable("students").update(
        [
          {
            id: id,
            fields: {
              forename: Student.forename,
              surname: Student.surname,
              uk_immigration_share_code: Student.uk_immigration_share_code,
              current_uk_residence_status: Student.current_uk_residence_status,
              current_uk_residence_status_document:
                Student.current_uk_residence_status_document,
              current_uk_residence_status_document_page_2:
                Student.current_uk_residence_status_document_page_2,
              applying_for_the_first_time: Student.applying_for_the_first_time,
              received_funds_from_student_finance:
                Student.received_funds_from_student_finance,
              academic_level_from_student_finance:
                Student.academic_level_from_student_finance,
              status_of_student_finance_application:
                Student.status_of_student_finance_application,
              crn: Student.crn,
              ssn: Student.ssn,
              changes_to_sfe_application: Student.changes_to_sfe_application,
              comments_for_student_finance_application:
                Student.comments_for_student_finance_application,
              supported_documents_for_loan_application:
                Student.supported_documents_for_loan_application,
              date_posted_student_finance_documents:
                Student.date_posted_student_finance_documents === ""
                  ? null
                  : Student.date_posted_student_finance_documents,
              did_you_complete_the_course_comments:
                Student.did_you_complete_the_course_comments,
              did_you_complete_the_course: Student.did_you_complete_the_course,
              how_many_terms_funded_by_student_finance:
                Student.how_many_terms_funded_by_student_finance,
              date_of_birth: Student.date_of_birth,
              living_in_uk: Student.living_in_uk,
              before_uk: Student.before_uk,
            },
          },
        ],
        function (err, records) {
          if (err) {
            console.log(err);
          } else {
            if (records) {
              push("/uos-jul-2022-share-code/thankyou");
            }
          }
        }
      );
    }
  };

  return (
    <section className="container-fluid p-3">
      <div className="text-center">
        <h5>LSC Admission Process</h5>
        <h4 style={{ color: "red" }}>
          This checklist needs to be filled by the applicant only{" "}
        </h4>
        <p>Please complete and submit the below questionnaire</p>
      </div>
      <div className="container bg-light border px-3">
        <form onSubmit={HandleSubmit}>
          <Input
            label="Temporary ID"
            name="id"
            helpText={`Temporary student ID`}
            disabled={true}
            value={Student.id}
            onChange={HandleChange}
            error={Error.id}
          />

          <Input
            label="First name"
            name="forename"
            helpText="As shown on your Passport"
            value={Student.forename}
            error={Error.forename}
            onChange={HandleChange}
          />
          <Input
            label="Last name"
            name="surname"
            helpText="As shown on your Passport"
            value={Student.surname}
            error={Error.surname}
            onChange={HandleChange}
          />
          <Input
            label="Date of birth (yyyy/mm/dd)"
            name="date_of_birth"
            helpText="Please confirm your date of birth - YYYY/MM/DD"
            type="date"
            value={Student.date_of_birth}
            error={Error.date_of_birth}
            onChange={HandleChange}
          />
          <Input
            label="When have you started living in the UK? (yyyy/mm/dd)"
            name="living_in_uk"
            type="date"
            value={Student.living_in_uk}
            error={Error.living_in_uk}
            onChange={HandleChange}
          />
          <Input
            label="Where have you been living before moving to the UK?"
            name="before_uk"
            type="text"
            value={Student.before_uk}
            error={Error.before_uk}
            onChange={HandleChange}
          />
          <Select
            label="What is your current UK Residence Status?"
            name="current_uk_residence_status"
            helpText="Please select your immigration status in the UK"
            value={Student.current_uk_residence_status}
            error={Error.current_uk_residence_status}
            onChange={HandleChange}
            options={current_uk_residence_status_options}
          />
          {Student.current_uk_residence_status !== "UK Citizen" &&
          Student.current_uk_residence_status !==
            "Non-EU Indefinite Leave to Remain" &&
          Student.current_uk_residence_status !== "Non-EU Settlement" &&
          Student.current_uk_residence_status !== "Non-EU Refugee" &&
          Student.current_uk_residence_status !== "Non-EU Leave to Remain" ? (
            <>
              <Input
                label="UK Immigration share code"
                name="uk_immigration_share_code"
                value={Student.uk_immigration_share_code}
                error={Error.uk_immigration_share_code}
                onChange={HandleChange}
              />
              <small>
                Prove your immigration status.{" "}
                <a
                  target="_blank"
                  href="https://www.gov.uk/view-prove-immigration-status"
                  rel="noreferrer"
                >
                  Click here to know more
                </a>
              </small>
            </>
          ) : (
            <></>
          )}
          <File
            label="Home office residence document"
            name="current_uk_residence_status_document"
            value={Student.current_uk_residence_status_document}
            error={Error.current_uk_residence_status_document}
            helpText="Please upload the image of the BRP or the EU settlement letter"
            onChange={HandleChange}
            onStateChange={HandleState}
          />
          <File
            label="Home office residence document Page 2"
            name="current_uk_residence_status_document_page_2"
            value={Student.current_uk_residence_status_document_page_2}
            error={Error.current_uk_residence_status_document_page_2}
            helpText="Please upload the image of the BRP or the EU settlement letter"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <h6 style={{ color: "red" }}>
            Note: Please provide accurate information below. By submitting the
            Fees Assessment checklist, you declare that the information you have
            provided is true and correct. You also understand that any wilful
            dishonesty might cause challenges to your enrolment completion and
            Student Finance application approval.
          </h6>
          {/*Is this your first time applying for the Student Finance Loan for tuition fees and/or maintenance?*/}
          <Select
            label="Is this your first time applying for the Student Finance Loan for tuition fees and/or maintenance?"
            name="applying_for_the_first_time"
            value={Student.applying_for_the_first_time}
            error={Error.applying_for_the_first_time}
            onChange={HandleChange}
            options={applying_for_the_first_time_options}
          />
          {Student.applying_for_the_first_time === "No" ? (
            <>
              <Select
                label="Have you ever received funds from the Student Finance Company for previous studies and course(s)?"
                name="received_funds_from_student_finance"
                value={Student.received_funds_from_student_finance}
                error={Error.received_funds_from_student_finance}
                onChange={HandleChange}
                options={received_funds_from_student_finance_options}
              />

              {Student.received_funds_from_student_finance ===
              "Yes - for more than an year" ? (
                <Select
                  label="How many terms were funded by the Student Finance (SLC)?"
                  name="how_many_terms_funded_by_student_finance"
                  value={Student.how_many_terms_funded_by_student_finance}
                  error={Error.how_many_terms_funded_by_student_finance}
                  onChange={HandleChange}
                  options={how_many_terms_funded_by_student_finance_options}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {Student.received_funds_from_student_finance !== "No" &&
          Student.applying_for_the_first_time === "No" ? (
            <>
              <Select
                label="For which academic level have you already received previous funds from the Student Finance Company?"
                name="academic_level_from_student_finance"
                value={Student.academic_level_from_student_finance}
                error={Error.academic_level_from_student_finance}
                onChange={HandleChange}
                options={academic_level_from_student_finance_options}
              />
              <Select
                label="Did you complete the course?"
                name="did_you_complete_the_course"
                value={Student.did_you_complete_the_course}
                error={Error.did_you_complete_the_course}
                onChange={HandleChange}
                options={did_you_complete_the_course}
              />
              {Student.did_you_complete_the_course === "No" ? (
                <Textarea
                  label="Why did you leave the course?"
                  name="did_you_complete_the_course_comments"
                  value={Student.did_you_complete_the_course_comments}
                  error={Error.did_you_complete_the_course_comments}
                  onChange={HandleChange}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <Select
            label={`What is the status of your Student Finance Application for the programme?`}
            name="status_of_student_finance_application"
            value={Student.status_of_student_finance_application}
            error={Error.status_of_student_finance_application}
            onChange={HandleChange}
            options={status_of_student_finance_application_options}
          />

          {Student.status_of_student_finance_application !==
          "Application not submitted" ? (
            <Input
              label="What is your Student Finance - Customer Reference Number (CRN)?"
              name="crn"
              value={Student.crn}
              error={Error.crn}
              onChange={HandleChange}
            />
          ) : (
            <></>
          )}

          {[
            "Fully Approved",
            "Approved - Changes Required (CoC)",
            "Approved - £0 tuition fees",
          ].includes(Student.status_of_student_finance_application) ? (
            <Input
              label="Please provide your Student Support Number (SSN)"
              name="ssn"
              helpText="You can find the SSN number below the bar code on the 'Payment Advice' page of your entitlement approval letter"
              value={Student.ssn}
              error={Error.ssn}
              onChange={HandleChange}
            />
          ) : (
            <></>
          )}

          {Student.status_of_student_finance_application ===
          "Approved - Changes Required (CoC)" ? (
            <Textarea
              label="What changes are required to your SFE's application?"
              name="changes_to_sfe_application"
              value={Student.changes_to_sfe_application}
              error={Error.changes_to_sfe_application}
              onChange={HandleChange}
            />
          ) : (
            <></>
          )}

          {["Approved - £0 tuition fees", "Rejected by SFE"].includes(
            Student.status_of_student_finance_application
          ) ? (
            <Textarea
              label="Please add your own comments regarding your Student Finance application for this course."
              name="comments_for_student_finance_application"
              value={Student.comments_for_student_finance_application}
              error={Error.comments_for_student_finance_application}
              onChange={HandleChange}
            />
          ) : (
            <></>
          )}

          {[
            "Processing - All documents provided",
            "Processing - Pending documents",
            "Rejected by SFE",
          ].includes(Student.status_of_student_finance_application) ? (
            <>
              <Select
                label="Which supporting documents have you already provided to the Student Finance Company to process your loan application?"
                name="supported_documents_for_loan_application"
                value={Student.supported_documents_for_loan_application}
                error={Error.supported_documents_for_loan_application}
                onChange={HandleChange}
                options={supported_documents_for_loan_application_options}
                multiselect={true}
              />
              <Input
                label="When have you posted your most recent document to the Student Finance Company?"
                name="date_posted_student_finance_documents"
                value={Student.date_posted_student_finance_documents}
                error={Error.date_posted_student_finance_documents}
                type="date"
                onChange={HandleChange}
              />
            </>
          ) : (
            <></>
          )}

          <File
            label="Upload Evidence of Student Finance Application or Approval Letter"
            name="evidence_student_finance_application"
            value={Student.evidence_student_finance_application}
            error={Error.evidence_student_finance_application}
            helpText="Please Upload Evidence of Student Finance Application or Approval Letter"
            onChange={HandleChange}
            onStateChange={HandleState}
          />
          <p>
            I certify that the above facts are true to the best of my knowledge
            and belief and I understand that I subject myself to disciplinary
            action in the event that the above facts are found to be falsified.
          </p>
          <div className="d-grid gap-4">
            <button type="submit" className="btn btn-primary btn-lg">
              Submit
            </button>
          </div>
          <br />
        </form>
      </div>
    </section>
  );
};

export default Register;
