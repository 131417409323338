import { useEffect, useState } from "react";
import axios from "axios";

const Input = (props) => {
  const [Overlay, setOverlay] = useState("hide");

  const [Attributes, setAttributes] = useState({
    name: "",
    label: "",
    value: "",
    disabled: false,
    helpText: "",
    onChange: null,
    error: "",
  });

  useEffect(() => {
    setAttributes({
      name: props.name,
      label: props.label,
      value: props.value,
      disabled: props.disabled ? true : false,
      helpText: props.helpText ? props.helpText : "",
      onChange: props.onChange,
      error: props.error ? "is-invalid" : "",
    });
  }, [props]);

  const HandleFile = async (e) => {
    setOverlay("");
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await axios
      .post("https://0arwzgjox3.execute-api.eu-west-2.amazonaws.com/applications/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        props.onStateChange(props.name, response.data.data.Location);
        setOverlay("hide");
      });
  };

  return (
    <div className="my-4">
      <div className={`overlay ${Overlay}`}>
        <center>
          <div className="spinner-grow text-primary" role="status"></div>
        </center>
      </div>
      <label htmlFor={Attributes.name} className="form-label">
        {Attributes.label}
      </label>
      <input
        type="file"
        onChange={HandleFile}
        className={`form-control form-control-lg ${Attributes.error}`}
        aria-describedby={`${Attributes.name}HelpBox`}
      />
      <input
        type="hidden"
        id={Attributes.name}
        name={Attributes.name}
        value={Attributes.value}
        onChange={(e) => {
          Attributes.onChange(e);
        }}
      />
      <div id={`${Attributes.name}HelpBox`} className="form-text">
        {Attributes.helpText}
      </div>
      <br />
      {Attributes.value ? (
        <div className="d-grid gap-4">
          {Attributes.value.includes(".jpg") || Attributes.value.includes(".gif") || Attributes.value.includes(".png") ? (
            <center>{Attributes.value ? <img src={Attributes.value} alt="Document Upload" className="w-25" /> : <></>}</center>
          ) : (
            <a href={Attributes.value} target="_blank" rel="noreferrer" className="btn btn-info">
              Check Uploaded Document
            </a>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Input;
