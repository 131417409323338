import { HashRouter, Switch, Route } from "react-router-dom";

import "./assets/scss/app.css";

import LoginComponent from "./components/login";
import RegisterComponent from "./components/register";
import ThankYou from "./components/thankyou";

import UosLoginComponent from "./components/uos/login";
import UosRegisterComponent from "./components/uos/register";
import UosThankYou from "./components/uos/thankyou";

import UosPart1LoginComponent from "./components/part1/login";
import UosPart1RegisterComponent from "./components/part1/register";
import UosPart1ThankYouComponent from "./components/part1/thankyou";

import UosSep21LoginComponent from "./components/uos-sep-21/login";
import UosSep21RegisterComponent from "./components/uos-sep-21/register";
import UosSep21ThankYouComponent from "./components/uos-sep-21/thankyou";

import OldRegisterComponent from "./components/old/register";
import OldLoginComponent from "./components/old/login";
import OldThankYou from "./components/old/thankyou";

import ShareCodeNcdLoginComponent from "./components/share-code-ncd/login";
import ShareCodeNcdRegisterComponent from "./components/share-code-ncd/register";
import ShareCodeNcdThankYouComponent from "./components/share-code-ncd/thankyou";

import CccuNov2021LoginComponent from "./components/cccu-nov-2021/login";
import CccuNov2021RegisterComponent from "./components/cccu-nov-2021/register";
import CccuNov2021ThankYouComponent from "./components/cccu-nov-2021/thankyou";

import NcdNov2021LoginComponent from "./components/ncd-nov-2021/login";
import NcdNov2021RegisterComponent from "./components/ncd-nov-2021/register";
import NcdNov2021ThankYouComponent from "./components/ncd-nov-2021/thankyou";

import UosJan2022LoginComponent from "./components/uos-jan-2022/login";
import UosJan2022RegisterComponent from "./components/uos-jan-2022/register";
import UosJan2022ThankyouComponent from "./components/uos-jan-2022/thankyou";

import UosJan2022ChecklistLoginComponent from "./components/uos-jan-2022-checklist/login";
import UosJan2022ChecklistRegisterComponent from "./components/uos-jan-2022-checklist/register";
import UosJan2022ChecklistThankyouComponent from "./components/uos-jan-2022-checklist/thankyou";

import CccuJan2022LoginComponent from "./components/cccu-jan-2022/login";
import CccuJan2022RegisterComponent from "./components/cccu-jan-2022/register";
import CccuJan2022ThankyouComponent from "./components/cccu-jan-2022/thankyou";

import CccuApr2022LoginComponent from "./components/cccu-apr-2022/login";
import CccuApr2022RegisterComponent from "./components/cccu-apr-2022/register";
import CccuApr2022ThankyouComponent from "./components/cccu-apr-2022/thankyou";

import CccuApr2022ShareCodeLoginComponent from "./components/cccu-apr-2022-share-code/login";
import CccuApr2022ShareCodeRegisterComponent from "./components/cccu-apr-2022-share-code/register";
import CccuApr2022ShareCodeThankyouComponent from "./components/cccu-apr-2022-share-code/thankyou";

import NcdApr2022ShareCodeLoginComponent from "./components/ncd-apr-2022-share-code/login";
import NcdApr2022ShareCodeRegisterComponent from "./components/ncd-apr-2022-share-code/register";
import NcdApr2022ShareCodeThankyouComponent from "./components/ncd-apr-2022-share-code/thankyou";

import NcdApr2022LoginComponent from "./components/ncd-apr-2022/login";
import NcdApr2022RegisterComponent from "./components/ncd-apr-2022/register";
import NcdApr2022ThankyouComponent from "./components/ncd-apr-2022/thankyou";

import CccuApr2022EduLoginComponent from "./components/cccu-apr-2022-edu/login";
import CccuApr2022EduRegisterComponent from "./components/cccu-apr-2022-edu/register";
import CccuApr2022EduThankyouComponent from "./components/cccu-apr-2022-edu/thankyou";

import UosJul2022LoginComponent from "./components/uos-jul-2022/login";
import UosJul2022RegisterComponent from "./components/uos-jul-2022/register";
import UosJul2022ThankyouComponent from "./components/uos-jul-2022/thankyou";

import UosJul2022EduLoginComponent from "./components/uos-jul-2022-edu/login";
import UosJul2022EduRegisterComponent from "./components/uos-jul-2022-edu/register";
import UosJul2022EduThankyouComponent from "./components/uos-jul-2022-edu/thankyou";

import UosJul2022ShareCodeLoginComponent from "./components/uos-jul-2022-share-code/login";
import UosJul2022ShareCodeRegisterComponent from "./components/uos-jul-2022-share-code/register";
import UosJul2022ShareCodeThankyouComponent from "./components/uos-jul-2022-share-code/thankyou";

const App = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/" exact>
          <LoginComponent />
        </Route>
        <Route path="/thankyou">
          <ThankYou />
        </Route>
        <Route path="/register/:id">
          <RegisterComponent />
        </Route>
        <Route path="/old" exact>
          <OldLoginComponent />
        </Route>
        <Route path="/old/thankyou">
          <OldThankYou />
        </Route>
        <Route path="/old/register/:id">
          <OldRegisterComponent />
        </Route>
        <Route path="/uos" exact>
          <UosLoginComponent />
        </Route>
        <Route path="/uos/thankyou">
          <UosThankYou />
        </Route>
        <Route path="/uos/register/:id">
          <UosRegisterComponent />
        </Route>
        <Route path="/share-code" exact>
          <UosPart1LoginComponent />
        </Route>
        <Route path="/share-code/thankyou">
          <UosPart1ThankYouComponent />
        </Route>
        <Route path="/share-code/register/:id">
          <UosPart1RegisterComponent />
        </Route>
        <Route path="/uos-sep-21" exact>
          <UosSep21LoginComponent />
        </Route>
        <Route path="/uos-sep-21/thankyou">
          <UosSep21ThankYouComponent />
        </Route>
        <Route path="/uos-sep-21/register/:id">
          <UosSep21RegisterComponent />
        </Route>
        <Route path="/share-code-ncd" exact>
          <ShareCodeNcdLoginComponent />
        </Route>
        <Route path="/share-code-ncd/thankyou">
          <ShareCodeNcdThankYouComponent />
        </Route>
        <Route path="/share-code-ncd/register/:id">
          <ShareCodeNcdRegisterComponent />
        </Route>
        <Route path="/cccu-nov-2021" exact>
          <CccuNov2021LoginComponent />
        </Route>
        <Route path="/cccu-nov-2021/thankyou">
          <CccuNov2021ThankYouComponent />
        </Route>
        <Route path="/cccu-nov-2021/register/:id">
          <CccuNov2021RegisterComponent />
        </Route>
        <Route path="/ncd-nov-2021" exact>
          <NcdNov2021LoginComponent />
        </Route>
        <Route path="/ncd-nov-2021/thankyou">
          <NcdNov2021ThankYouComponent />
        </Route>
        <Route path="/ncd-nov-2021/register/:id">
          <NcdNov2021RegisterComponent />
        </Route>
        <Route path="/uos-jan-2022-checklist" exact>
          <UosJan2022ChecklistLoginComponent />
        </Route>
        <Route path="/uos-jan-2022-checklist/thankyou">
          <UosJan2022ChecklistThankyouComponent />
        </Route>
        <Route path="/uos-jan-2022-checklist/register/:id">
          <UosJan2022ChecklistRegisterComponent />
        </Route>
        <Route path="/uos-jan-2022" exact>
          <UosJan2022LoginComponent />
        </Route>
        <Route path="/uos-jan-2022/thankyou">
          <UosJan2022ThankyouComponent />
        </Route>
        <Route path="/uos-jan-2022/register/:id">
          <UosJan2022RegisterComponent />
        </Route>
        <Route path="/cccu-jan-2022" exact>
          <CccuJan2022LoginComponent />
        </Route>
        <Route path="/cccu-jan-2022/thankyou">
          <CccuJan2022ThankyouComponent />
        </Route>
        <Route path="/cccu-jan-2022/register/:id">
          <CccuJan2022RegisterComponent />
        </Route>
        <Route path="/cccu-apr-2022" exact>
          <CccuApr2022LoginComponent />
        </Route>
        <Route path="/cccu-apr-2022/thankyou">
          <CccuApr2022ThankyouComponent />
        </Route>
        <Route path="/cccu-apr-2022/register/:id">
          <CccuApr2022RegisterComponent />
        </Route>
        <Route path="/cccu-apr-2022-share-code" exact>
          <CccuApr2022ShareCodeLoginComponent />
        </Route>
        <Route path="/cccu-apr-2022-share-code/thankyou">
          <CccuApr2022ShareCodeThankyouComponent />
        </Route>
        <Route path="/cccu-apr-2022-share-code/register/:id">
          <CccuApr2022ShareCodeRegisterComponent />
        </Route>
        <Route path="/ncd-apr-2022" exact>
          <NcdApr2022LoginComponent />
        </Route>
        <Route path="/ncd-apr-2022/thankyou">
          <NcdApr2022ThankyouComponent />
        </Route>
        <Route path="/ncd-apr-2022/register/:id">
          <NcdApr2022RegisterComponent />
        </Route>
        <Route path="/ncd-apr-2022-share-code" exact>
          <NcdApr2022ShareCodeLoginComponent />
        </Route>
        <Route path="/ncd-apr-2022-share-code/thankyou">
          <NcdApr2022ShareCodeThankyouComponent />
        </Route>
        <Route path="/ncd-apr-2022-share-code/register/:id">
          <NcdApr2022ShareCodeRegisterComponent />
        </Route>
        <Route path="/cccu-apr-2022-edu" exact>
          <CccuApr2022EduLoginComponent />
        </Route>
        <Route path="/cccu-apr-2022-edu/thankyou">
          <CccuApr2022EduThankyouComponent />
        </Route>
        <Route path="/cccu-apr-2022-edu/register/:id">
          <CccuApr2022EduRegisterComponent />
        </Route>
        <Route path="/uos-jul-2022" exact>
          <UosJul2022LoginComponent />
        </Route>
        <Route path="/uos-jul-2022/thankyou">
          <UosJul2022ThankyouComponent />
        </Route>
        <Route path="/uos-jul-2022/register/:id">
          <UosJul2022RegisterComponent />
        </Route>

        <Route path="/uos-jul-2022-share-code" exact>
          <UosJul2022ShareCodeLoginComponent />
        </Route>
        <Route path="/uos-jul-2022-share-code/thankyou">
          <UosJul2022ShareCodeThankyouComponent />
        </Route>
        <Route path="/uos-jul-2022-share-code/register/:id">
          <UosJul2022ShareCodeRegisterComponent />
        </Route>

        <Route path="/uos-jul-2022-edu" exact>
          <UosJul2022EduLoginComponent />
        </Route>
        <Route path="/uos-jul-2022-edu/thankyou">
          <UosJul2022EduThankyouComponent />
        </Route>
        <Route path="/uos-jul-2022-edu/register/:id">
          <UosJul2022EduRegisterComponent />
        </Route>
      </Switch>
    </HashRouter>
  );
};

export default App;
