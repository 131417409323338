import { useState } from "react";
import { useHistory } from "react-router-dom";
import { cccuApr2022 as Airtable } from "../../airtable";

import LSCLogo from "../../assets/img/logo.svg";

const Login = () => {
  const [Id, setId] = useState("");
  const [IdError, setIdError] = useState("");

  const { push } = useHistory();

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (Id === "") {
      setIdError("Please enter the student ID");
    } else {
      setIdError("");
      Airtable("students")
        .select({
          maxRecords: 1,
          filterByFormula: `({id} = '${Id}')`,
        })
        .firstPage((err, records) => {
          if (err) console.error(err);
          else {
            if (records.length === 0) {
              setIdError("The entered student ID doesn't exist.");
            } else {
              push(`/cccu-apr-2022-edu/register/${records[0].id}`);
            }
          }
        });
    }
  };

  return (
    <section className="container-fluid text-center p-4">
      <img
        src={LSCLogo}
        alt="London School of Commerce Logo"
        className="p-3 mb-3"
        width="150"
      ></img>
      <h5>Welcome to</h5>
      <h1 className="display-6 mb-8">London School of Commerce</h1>
      <br />
      <div className="container bg-light border px-3 py-4">
        <p className="mb-4">
          To start your checking process for your course please enter your ID
          sent on the email.
        </p>
        <form onSubmit={HandleSubmit} className="d-grid gap-4">
          <input
            type="text"
            className="form-control form-control-lg"
            value={Id}
            onChange={(e) => setId(e.target.value)}
            placeholder="Student Reference ID"
          ></input>
          <button type="submit" className="btn btn-primary btn-lg">
            Submit
          </button>
        </form>
        <br />
        {IdError !== "" ? (
          <div className="alert alert-danger" role="alert">
            {IdError}
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default Login;
