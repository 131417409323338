import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { uosJuly2022 as Airtable } from "../../airtable";
import Input from "../form/input";
import Select from "../form/select";
import Textarea from "../form/textarea";
import File from "../form/file";

const Register = () => {
  const { id } = useParams();
  const { push } = useHistory();

  const [University] = useState("University of Suffolk");

  const [countries] = useState([
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ]);

  const [Student, setStudent] = useState({
    id: "",
    campus: "",
    forename: "",
    surname: "",
    education_details: "",
    education_details_1: "",
    education_details_2: "",
    education_1: "",
    education_2: "",
    education_3: "",
    education_country_1: "",
    education_country_2: "",
    education_country_3: "",
  });
  const [Error, setError] = useState({
    id: false,
    campus: false,
    forename: false,
    surname: false,
    education_details: false,
    education_details_1: false,
    education_details_2: false,
    education_1: false,
    education_2: false,
    education_3: false,
    education_country_1: false,
    education_country_2: false,
    education_country_3: false,
  });
  useEffect(() => {
    Airtable("students").find(id, function (err, record) {
      if (err) console.error(err);
      if (record) {
        setStudent({
          id: "id" in record.fields ? record.fields.id : "",
          campus: "campus" in record.fields ? record.fields.campus[0] : "",
          forename: "forename" in record.fields ? record.fields.forename : "",
          surname: "surname" in record.fields ? record.fields.surname : "",
          education_details:
            "education_details" in record.fields
              ? record.fields.education_details
              : "",
          education_details_1:
            "education_details_1" in record.fields
              ? record.fields.education_details_1
              : "",
          education_details_2:
            "education_details_2" in record.fields
              ? record.fields.education_details_2
              : "",
          education_1:
            "education_1" in record.fields ? record.fields.education_1 : "",
          education_2:
            "education_2" in record.fields ? record.fields.education_2 : "",
          education_3:
            "education_3" in record.fields ? record.fields.education_3 : "",
          education_country_1:
            "education_country_1" in record.fields
              ? record.fields.education_country_1
              : "",
          education_country_2:
            "education_country_2" in record.fields
              ? record.fields.education_country_2
              : "",
          education_country_3:
            "education_country_3" in record.fields
              ? record.fields.education_country_3
              : "",
        });
      } else {
        push("/uos-jul-2022-edu/");
      }
    });
  }, [id, push]);

  const HandleChange = (e) => {
    setStudent({ ...Student, [e.target.name]: e.target.value });
  };

  const HandleState = (name, value) => {
    setStudent({ ...Student, [name]: value });
  };

  const HandleValidate = (name, form) => {
    let flag = 0;
    const error = {
      id: false,
      campus: false,
      forename: false,
      surname: false,
      education_details: false,
      education_details_1: false,
      education_details_2: false,
      education_1: false,
      education_2: false,
      education_3: false,
      education_country_1: false,
      education_country_2: false,
      education_country_3: false,
    };

    if (name === "id" || form) {
      if (Student.id === "") {
        error.id = true;
        flag++;
      } else {
        error.id = false;
      }
    }

    if (name === "forename" || form) {
      if (Student.forename === "") {
        error.forename = true;
        flag++;
      } else {
        error.forename = false;
      }
    }

    if (name === "surname" || form) {
      if (Student.surname === "") {
        error.surname = true;
        flag++;
      } else {
        error.surname = false;
      }
    }

    setError(error);
    return flag;
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const error = HandleValidate("", true);
    if (error === 0) {
      Airtable("students").update(
        [
          {
            id: id,
            fields: {
              forename: Student.forename,
              surname: Student.surname,
              education_details: Student.education_details,
              education_details_1: Student.education_details_1,
              education_details_2: Student.education_details_2,
              education_1: Student.education_1,
              education_2: Student.education_2,
              education_3: Student.education_3,
              education_country_1: Student.education_country_1,
              education_country_2: Student.education_country_2,
              education_country_3: Student.education_country_3,
            },
          },
        ],
        function (err, records) {
          if (err) {
            console.log(err);
          } else {
            if (records) {
              push("/uos-jul-2022-edu/thankyou");
            }
          }
        }
      );
    }
  };

  return (
    <section className="container-fluid p-3">
      <div className="text-center">
        <h5>LSC Admission Process</h5>
        <h4 style={{ color: "red" }}>
          This checklist needs to be filled by the applicant only{" "}
        </h4>
        <p>
          Please complete and submit the below questionnaire prior to your
          one-to-one virtual registration with an LSC Student Advisor.
        </p>
      </div>
      <div className="container bg-light border px-3">
        <form onSubmit={HandleSubmit}>
          <Input
            label="Student Reference ID"
            name="id"
            helpText={`ID sent on email`}
            disabled={true}
            value={Student.id}
            onChange={HandleChange}
            error={Error.id}
          />

          <Input
            label="First name"
            name="forename"
            helpText="As shown on your Passport"
            value={Student.forename}
            error={Error.forename}
            onChange={HandleChange}
          />
          <Input
            label="Last name"
            name="surname"
            helpText="As shown on your Passport"
            value={Student.surname}
            error={Error.surname}
            onChange={HandleChange}
          />

          <h4>
            Please provide the details of your previous qualification (School or
            college from overseas or the UK)
          </h4>
          <hr />

          <Textarea
            label="Education 1: Name and address of the Previous School/College - Qualification achieved - Course start date (MM/YYYY) - end date (MM/YYYY)"
            name="education_details"
            value={Student.education_details}
            error={Error.education_details}
            onChange={HandleChange}
          />

          <Select
            label="Country of Education 1"
            name="education_country_1"
            helpText="Please select the country of education"
            value={Student.education_country_1}
            error={Error.education_country_1}
            onChange={HandleChange}
            options={countries}
          />

          <File
            label="Education 1: Upload you educational certificates or transcripts"
            name="education_1"
            value={Student.education_1}
            error={Error.education_1}
            helpText="Please Upload the evidence of Education"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <Textarea
            label="Education 2: Name and address of the Previous School/College - Qualification achieved - Course start date (MM/YYYY) - end date (MM/YYYY)"
            name="education_details_1"
            value={Student.education_details_1}
            error={Error.education_details_1}
            onChange={HandleChange}
          />
          <Select
            label="Country of Education 2"
            name="education_country_2"
            helpText="Please select the country of education"
            value={Student.education_country_2}
            error={Error.education_country_2}
            onChange={HandleChange}
            options={countries}
          />

          <File
            label="Education 2: Upload you educational certificates or transcripts"
            name="education_2"
            value={Student.education_2}
            error={Error.education_2}
            helpText="Please Upload the evidence of Education"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <Textarea
            label="Education 3: Name and address of the Previous School/College - Qualification achieved - Course start date (MM/YYYY) - end date (MM/YYYY)"
            name="education_details_2"
            value={Student.education_details_2}
            error={Error.education_details_2}
            onChange={HandleChange}
          />

          <Select
            label="Country of Education 3"
            name="education_country_3"
            helpText="Please select the country of education"
            value={Student.education_country_3}
            error={Error.education_country_3}
            onChange={HandleChange}
            options={countries}
          />

          <File
            label="Education 3: Upload you educational certificates or transcripts"
            name="education_3"
            value={Student.education_3}
            error={Error.education_3}
            helpText="Please Upload the evidence of Education"
            onChange={HandleChange}
            onStateChange={HandleState}
          />

          <div className="d-grid gap-4">
            <button type="submit" className="btn btn-primary btn-lg">
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Register;
