import { useEffect, useState } from "react";

const Textarea = (props) => {
  const [Attributes, setAttributes] = useState({
    name: "",
    label: "",
    value: "",
    disabled: false,
    helpText: "",
    onChange: null,
  });

  useEffect(() => {
    setAttributes({
      name: props.name,
      label: props.label,
      value: props.value,
      disabled: props.disabled ? true : false,
      helpText: props.helpText ? props.helpText : "",
      onChange: props.onChange,
    });
  }, [props]);

  return (
    <div className="my-4">
      <label htmlFor={Attributes.name} className="form-label">
        {Attributes.label}
      </label>
      <textarea
        type="text"
        onChange={(e) => {
          Attributes.onChange(e);
        }}
        id={Attributes.name}
        name={Attributes.name}
        value={Attributes.value}
        className="form-control form-control-lg"
        aria-describedby={`${Attributes.name}HelpBox`}
        disabled={Attributes.disabled}
      />
      <div id={`${Attributes.name}HelpBox`} className="form-text">
        {Attributes.helpText}
      </div>
      {JSON.stringify(Attributes.onChange)}
    </div>
  );
};

export default Textarea;
