import { useEffect, useState } from "react";

const Input = (props) => {
  const [Attributes, setAttributes] = useState({
    name: "",
    label: "",
    value: "",
    disabled: false,
    helpText: "",
    onChange: null,
    type: "",
    error: false,
  });

  useEffect(() => {
    setAttributes({
      name: props.name,
      label: props.label,
      value: props.value,
      disabled: props.disabled ? true : false,
      helpText: props.helpText ? props.helpText : "",
      onChange: props.onChange,
      type: props.type ? props.type : "",
      error: props.error ? "is-invalid" : "",
    });
  }, [props]);

  return (
    <div className="my-4">
      <label htmlFor={Attributes.name} className="form-label">
        {Attributes.label}
      </label>
      <input
        type={Attributes.type}
        onChange={(e) => {
          Attributes.onChange(e);
        }}
        id={Attributes.name}
        name={Attributes.name}
        value={Attributes.value}
        className={`form-control form-control-lg ${Attributes.error}`}
        aria-describedby={`${Attributes.name}HelpBox`}
        disabled={Attributes.disabled}
      />
      <div id={`${Attributes.name}HelpBox`} className="form-text">
        {Attributes.helpText}
      </div>
      {JSON.stringify(Attributes.onChange)}
    </div>
  );
};

export default Input;
