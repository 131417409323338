import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { ncdNov2021 as Airtable } from "../../airtable";
import Input from "../form/input";
import Select from "../form/select";
import File from "../form/file";

const Register = () => {
  const { id } = useParams();
  const { push } = useHistory();

  const [current_uk_residence_status_options] = useState([
    "UK Citizen",
    "EU Scheme Settled",
    "EU Scheme Pre-Settled",
    "EU Family Member",
    "EU Pending application for EU Scheme",
    "Non-EU Indefinite Leave to Remain",
    "Non-EU Settlement",
    "Non-EU Leave to Remain",
    "Non-EU Refugee",
    "Other",
  ]);

  const [Student, setStudent] = useState({
    id: "",
    forename: "",
    surname: "",
    current_uk_residence_status: "",
    current_uk_residence_status_document: "",
    current_uk_residence_status_document_page_2: "",
    uk_immigration_share_code: "",
  });
  const [Error, setError] = useState({
    id: false,
    current_uk_residence_status: false,
    current_uk_residence_status_document: false,
    current_uk_residence_status_document_page_2: false,
    uk_immigration_share_code: false,
  });
  useEffect(() => {
    Airtable("students").find(id, function (err, record) {
      if (err) console.error(err);
      if (record) {
        //setUniversity("Canterbury Christ Church University");
        setStudent({
          id: "id" in record.fields ? record.fields.id : "",
          forename: "forename" in record.fields ? record.fields.forename : "",
          surname: "surname" in record.fields ? record.fields.surname : "",
          current_uk_residence_status: "current_uk_residence_status" in record.fields ? record.fields.current_uk_residence_status : "",
          current_uk_residence_status_document:
            "current_uk_residence_status_document" in record.fields ? record.fields.current_uk_residence_status_document : "",
          current_uk_residence_status_document_page_2:
            "current_uk_residence_status_document_page_2" in record.fields ? record.fields.current_uk_residence_status_document_page_2 : "",
          uk_immigration_share_code: "uk_immigration_share_code" in record.fields ? record.fields.uk_immigration_share_code : "",
        });
      } else {
        push("/share-code-ncd");
      }
    });
  }, [id, push]);

  const HandleChange = (e) => {
    setStudent({ ...Student, [e.target.name]: e.target.value });
  };

  const HandleState = (name, value) => {
    setStudent({ ...Student, [name]: value });
  };

  const HandleValidate = (name, form) => {
    let flag = 0;
    const error = {
      id: false,
      forename: false,
      surname: false,
      current_uk_residence_status: false,
      current_uk_residence_status_document: false,
      uk_immigration_share_code: false,
    };

    if (name === "id" || form) {
      if (Student.id === "") {
        error.id = true;
        flag++;
      } else {
        error.id = false;
      }
    }

    if (name === "forename" || form) {
      if (Student.forename === "") {
        error.forename = true;
        flag++;
      } else {
        error.forename = false;
      }
    }

    if (name === "surname" || form) {
      if (Student.surname === "") {
        error.surname = true;
        flag++;
      } else {
        error.surname = false;
      }
    }

    if ((name === "uk_immigration_share_code" || form) && Student.current_uk_residence_status !== "UK Citizen") {
      if (Student.uk_immigration_share_code === "") {
        error.uk_immigration_share_code = true;
        flag++;
      } else {
        error.uk_immigration_share_code = false;
      }
    }

    if (name === "current_uk_residence_status" || form) {
      if (Student.current_uk_residence_status === "") {
        error.current_uk_residence_status = true;
        flag++;
      } else {
        error.current_uk_residence_status = false;
      }
    }

    setError(error);
    return flag;
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const error = HandleValidate("", true);
    if (error === 0) {
      Airtable("students").update(
        [
          {
            id: id,
            fields: {
              forename: Student.forename,
              surname: Student.surname,
              uk_immigration_share_code: Student.uk_immigration_share_code,
              current_uk_residence_status: Student.current_uk_residence_status,
              current_uk_residence_status_document: Student.current_uk_residence_status_document,
              current_uk_residence_status_document_page_2: Student.current_uk_residence_status_document_page_2,
            },
          },
        ],
        function (err, records) {
          if (err) {
            console.log(err);
          } else {
            if (records) {
              push("/share-code-ncd/thankyou");
            }
          }
        }
      );
    }
  };

  return (
    <section className="container-fluid p-3">
      <div className="text-center">
        <h5>LSC Admission Process</h5>
        <p>Please complete and submit the below questionnaire prior to your one-to-one virtual registration with an LSC Student Advisor.</p>
      </div>
      <div className="container bg-light border px-3">
        <form onSubmit={HandleSubmit}>
          <Input
            label="Temporary ID"
            name="id"
            helpText={`Temporary student ID`}
            disabled={true}
            value={Student.id}
            onChange={HandleChange}
            error={Error.id}
          />

          <Input
            label="First name"
            name="forename"
            helpText="As shown on your Passport"
            value={Student.forename}
            error={Error.forename}
            onChange={HandleChange}
          />
          <Input
            label="Last name"
            name="surname"
            helpText="As shown on your Passport"
            value={Student.surname}
            error={Error.surname}
            onChange={HandleChange}
          />
          <Select
            label="What is your current UK Residence Status?"
            name="current_uk_residence_status"
            helpText="Please select your immigration status in the UK"
            value={Student.current_uk_residence_status}
            error={Error.current_uk_residence_status}
            onChange={HandleChange}
            options={current_uk_residence_status_options}
          />
          {Student.current_uk_residence_status !== "UK Citizen" ? (
            <>
              <Input
                label="UK Immigration share code"
                name="uk_immigration_share_code"
                value={Student.uk_immigration_share_code}
                error={Error.uk_immigration_share_code}
                onChange={HandleChange}
              />
              <small>
                Prove your immigration status.{" "}
                <a target="_blank" href="https://www.gov.uk/view-prove-immigration-status" rel="noreferrer">
                  Click here to know more
                </a>
              </small>
            </>
          ) : (
            <></>
          )}
          <File
            label="Home office residence document"
            name="current_uk_residence_status_document"
            value={Student.current_uk_residence_status_document}
            error={Error.current_uk_residence_status_document}
            helpText="Please upload the image of the BRP or the EU settlement letter"
            onChange={HandleChange}
            onStateChange={HandleState}
          />
          <File
            label="Home office residence document Page 2"
            name="current_uk_residence_status_document_page_2"
            value={Student.current_uk_residence_status_document_page_2}
            error={Error.current_uk_residence_status_document_page_2}
            helpText="Please upload the image of the BRP or the EU settlement letter"
            onChange={HandleChange}
            onStateChange={HandleState}
          />
          <p>
            I certify that the above facts are true to the best of my knowledge and belief and I understand that I subject myself to disciplinary
            action in the event that the above facts are found to be falsified.
          </p>
          <div className="d-grid gap-4">
            <button type="submit" className="btn btn-primary btn-lg">
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Register;
