import LSCLogo from "../../assets/img/logo.svg";

const ThankYou = () => {
  return (
    <section className="container-fluid text-center p-4">
      <img
        src={LSCLogo}
        alt="London School of Commerce Logo"
        className="p-3 mb-3"
        width="150"
      ></img>
      <h5>LSC Admission Process</h5>
      <h1 className="display-6 mb-8">Thank you for your details</h1>
    </section>
  );
};

export default ThankYou;
