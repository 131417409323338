var Airtable = require("airtable");

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
});

export default Airtable.base("appzRuHTacPeiB8sY");

export const uosBase = Airtable.base("appyV1gYqlgi3W7Bi");
export const cccuBase = Airtable.base("appzRuHTacPeiB8sY");
export const uosSepBase = Airtable.base("app5dWzvuKZ71qfAR");
export const cccuNov2021 = Airtable.base("appDPWcLFzQrhiw1A");
export const ncdNov2021 = Airtable.base("appfeyhZL3uCFzKDe");

export const AirtableOld = Airtable.base("appzRuHTacPeiB8sY");

export const uosJan2022 = Airtable.base("appVohqnE2Y4G8Kko");
export const cccuJan2022 = Airtable.base("app9Y6FmMhv1UgK1O");
export const cccuApr2022 = Airtable.base("appxMyrhiQ7HpZra6");
export const ncdApr2022 = Airtable.base("appXTUU2n9wE0HQ1x");
export const uosJuly2022 = Airtable.base("apph64dr6qpkC2Lb1");
